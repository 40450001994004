import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BaseComponent from "./BaseComponent";
import { Card, CardActions, IconButton } from "@material-ui/core";

import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { red } from "@material-ui/core/colors";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      height: "100hv",
    },
    content: {
      paddingTop: theme.spacing(2),
    },

    logo: {
      height: 0,
      paddingTop: "22.6%", // 16:9,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9,
    },
    avatar: {
      backgroundColor: red[500],
    },
    sozialButtons: {
      justifyContent: "center",
    },
    sozialIcons: {
      "& svg": {
        fontSize: 50,
      },
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {}

interface IStates {}

class Social extends BaseComponent<IProps, IStates> {
  interval: any;

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.root}>
        <CardActions className={classes.sozialButtons}>
          <IconButton className={classes.sozialIcons} size="medium" aria-label="instagramm" href="https://www.instagram.com/raynepgrocks/">
            <InstagramIcon />
          </IconButton>
          <IconButton className={classes.sozialIcons} aria-label="YouTube" href="https://www.youtube.com/channel/UCZfwfpufBJ_Q6yJbDzX8fVA">
            <YouTubeIcon />
          </IconButton>
          <IconButton className={classes.sozialIcons} aria-label="facebook" href="https://www.facebook.com/rayne.p.grocks">
            <FacebookIcon />
          </IconButton>
        </CardActions>
      </Card>
    );
  }
}
export default withStyles(styles)(withRouter(Social));
