import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BaseComponent from "./BaseComponent";
import { red } from "@material-ui/core/colors";
import { Helmet } from "react-helmet";
import Frame from "./Frame";
import ImgContentBox from "./ImgContentBox";
import Typography from "@material-ui/core/Typography";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Social from "./Social";
import clsx from "clsx";
import {
  Grid,
  IconButton,
  CardContent,
  Card,
  CircularProgress,
  CardHeader,
  Avatar,
  CardMedia,
  CardActions,
  Fade,
  Button,
  Paper,
  Fab,
  Modal,
  CardActionArea,
  Slide,
  Box,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import YT from "./YT";
import ProductCalc from "./ProductCalc";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0),
    },
    blockSpace: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    blockSpaceButtom: {
      paddingBottom: theme.spacing(2),
    },
    content: {
      paddingTop: theme.spacing(2),
    },

    lightBox: {
      padding: 0,
      height: "100vh",
      backgroundColor: theme.palette.background.default,
    },
    lightMedia: {
      height: "100vh",
    },
    lightImg: {
      height: "100%",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "center",
      backgroundPositionY: "top",
      backgroundSize: "contain",
    },
    favs: {
      position: "absolute",
      bottom: "50vh",
      right: theme.spacing(2),
      left: theme.spacing(2),
    },
    fab: {
      opacity: 0.5,
      "&:hover": {
        opacity: 0.9,
      },
    },
    favClose: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
    smallText: {
      opacity: 0.5,
      paddingTop: theme.spacing(2),
    },
    teaserText: {
      textAlign: "center",
      // fontSize: "20px",
      marginRight: "auto",
      marginLeft: "auto",
      textShadow: "2px 2px 5px #000",
    },
    mainSelect: {
      width: "100%",
      height: "10vh",
      opacity: 0.7,
    },
    logo: {
      height: 0,
      paddingTop: "22.6%", // 16:9,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9,
    },
    button: {
      margin: theme.spacing(1),
    },
    avatar: {
      backgroundColor: red[500],
    },
    sozialButtons: {
      justifyContent: "center",
    },
    sozialIcons: {
      "& svg": {
        fontSize: 50,
      },
    },
    imageList: {
      width: "100%",
      transform: "translateZ(0)",
    },
    paper: {
      padding: "6px 16px",
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },

    icon: {
      color: "white",
    },

    titleBar: {
      background:
        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {}

interface IStates {
  headerBilder: Array<string>;
  productBilder: Array<string>;
  showImage: boolean;
  imageIdx: number;
  _imageIdx: number;
  mediaView: boolean;
  mediaDirection: boolean;
}

class ProductBilder extends BaseComponent<IProps, IStates> {
  interval: any;
  diashowRef = React.createRef<HTMLDivElement>();
  timeLineRef = React.createRef<HTMLDivElement>();
  touchStartPosX: number = 0;
  touchEndPosX: number = 0;

  constructor(props: IProps) {
    super(props);
    this.state = {
      headerBilder: [],
      productBilder: [],
      showImage: false,
      imageIdx: 0,
      _imageIdx: 0,
      mediaView: true,
      mediaDirection: true,
    };
  }

  componentDidMount() {
    fetch(
      "https://www.professionelle-produktbilder.de/src.php?p=/produkte/header/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({ headerBilder: res });
      })
      .catch((res) => {
        this.setState({ headerBilder: [] });
      });

    fetch(
      "https://www.professionelle-produktbilder.de/src.php?p=/produkte/produkte/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({ productBilder: res, showImage: false });
      })
      .catch((res) => {
        this.setState({ productBilder: [] });
      });
  }

  imgPriv(direction: boolean) {
    let idx = this.state.imageIdx;
    if (idx > 0) {
      idx--;
    } else {
      idx = this.state.productBilder.length - 1;
    }
    this.setState({
      _imageIdx: idx,
      mediaView: false,
      mediaDirection: direction,
    });
  }

  imgNext(direction: boolean) {
    let idx = this.state.imageIdx;
    if (idx < this.state.productBilder.length - 1) {
      idx++;
    } else {
      idx = 0;
    }
    this.setState({
      _imageIdx: idx,
      mediaView: false,
      mediaDirection: direction,
    });
  }

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;

    // @ts-ignore
    // @ts-ignore
    return (
      <React.Fragment>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
          <link rel="canonical" href={window.location.href} />
          <meta
            name="description"
            content={process.env.REACT_APP_DESCRIPTION}
          />
        </Helmet>

        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <Frame title={"Professionelle Produktbilder"}>
              <Grid
                className={classes.content}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} className={classes.content}>
                  <Card style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                    <CardHeader
                      avatar={
                        <Avatar alt="Rayne p. Grocks" src="/avatar.jpg" />
                      }
                      action={<></>}
                      title="Rayne p. Grocks"
                      subheader="Produkt Produktfotograf"
                    />
                    {/*<CardMedia component="img" className={classes.logo} image="/logo.png" title="logo" />*/}
                    <CardContent>
                      <Typography
                        className={classes.teaserText}
                        color="textSecondary"
                        variant="h4"
                        component="h1"
                        align="center"
                      >
                        Mit exzellenten Produktfotos sofort mehr Umsatz
                        erziehlen.
                      </Typography>
                      <Typography
                        className={classes.teaserText}
                        variant="subtitle1"
                        color="textSecondary"
                        component="p"
                      >
                        Einer der Größten faktoren um die Conversion auf Amazon,
                        eBay oder in deinem eigenen Shop zu steigern ist es, mit
                        professionellen bzw. emotionalen Bildern Ihre Artikel zu
                        präsentieren. Professionelle Produktbilder in nur{" "}
                        <strong>6</strong> Schritten.
                      </Typography>

                      <Typography
                        className={classes.teaserText}
                        color="textSecondary"
                        variant="h4"
                        component="h1"
                        align="center"
                      >
                        Meine Spezialität
                      </Typography>

                      <Typography
                        className={classes.teaserText}
                        style={{ paddingTop: "2vh" }}
                        color="textSecondary"
                        variant="h4"
                        component="h1"
                        align="center"
                      >
                        <Fab
                          className={clsx("exciter", classes.fab)}
                          aria-label="add"
                          color="primary"
                          onClick={() => {
                            console.log("CLKICK");
                            if (this.diashowRef.current !== null) {
                              this.diashowRef.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }}
                        >
                          <KeyboardArrowDownIcon />
                        </Fab>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} ref={this.diashowRef}>
                  <ImgContentBox
                    timeout={8000}
                    images={this.state.headerBilder}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classes.teaserText}
                    style={{ paddingTop: "2vh", paddingBottom: "2vh" }}
                    color="textSecondary"
                    variant="h4"
                    component="h1"
                    align="center"
                  >
                    <Fab
                      className={classes.fab}
                      aria-label="add"
                      color="primary"
                      onClick={() => {
                        if (this.timeLineRef.current !== null) {
                          this.timeLineRef.current.scrollIntoView({
                            behavior: "smooth",
                          });
                        }
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </Fab>
                  </Typography>
                  <hr />
                </Grid>
                <Grid item xs={12} ref={this.timeLineRef}>
                  <Typography
                    variant="h3"
                    component="h1"
                    align="center"
                    className={classes.blockSpace}
                  >
                    Einfacher gehts nicht
                  </Typography>
                  <Typography variant="h5" component="h1" align="center">
                    6 Schritte zu deinen neuen Produktbildern
                  </Typography>
                  <Timeline align="alternate">
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                          <Typography variant="h6" component="h1">
                            Schritt 1
                          </Typography>
                          <Typography>
                            Du packst deine Artikel in ein Paket. Am besten
                            gleich mit einem Retouren Label.
                          </Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                          <Typography variant="h6" component="h1">
                            Schritt 2
                          </Typography>
                          <Typography>
                            Du sendest das Paket ins Fotostudio und sagst mir
                            deine Wünsche.
                          </Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        {" "}
                        <Paper elevation={3} className={classes.paper}>
                          <Typography variant="h6" component="h1">
                            Schritt 3
                          </Typography>
                          <Typography>
                            Ich fotografiere deine Produkte.
                          </Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                          <Typography variant="h6" component="h1">
                            Schritt 4
                          </Typography>
                          <Typography>
                            Ich stelle Dir die Bilder als Download zur
                            verfügung.
                          </Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                          <Typography variant="h6" component="h1">
                            Schritt 5
                          </Typography>
                          <Typography>
                            Wenn du zufrieden bist, sende ich dir Deine Artikel
                            per Retoure zurück.
                          </Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                          <Typography variant="h6" component="h1">
                            Schritt 6
                          </Typography>
                          <Typography>
                            Rechnung per PDF. (Umsatzsteuerfreie Leistungen
                            gemäß §19 UStG)
                          </Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    component="h6"
                    align="center"
                    className={classes.smallText}
                  >
                    Unverbindlicher
                  </Typography>
                  <Typography
                    variant="h3"
                    component="h1"
                    align="center"
                    className={classes.blockSpaceButtom}
                  >
                    Preis kalkulator
                  </Typography>
                  <ProductCalc />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    component="h1"
                    align="center"
                    className={classes.blockSpace}
                  >
                    Referenzen
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ImageList
                        rowHeight={150}
                        className={classes.imageList}
                        cols={3}
                        gap={0}
                      >
                        {this.state.productBilder.map(
                          (item: string, i: number) => (
                            <ImageListItem key={item}>
                              <img
                                src={item}
                                alt={"asda"}
                                onClick={() => {
                                  this.setState({
                                    showImage: true,
                                    imageIdx: i,
                                  });
                                }}
                              />
                              <ImageListItemBar
                                position="top"
                                actionIcon={
                                  <IconButton
                                    aria-label={`star ${item}`}
                                    className={classes.icon}
                                  >
                                    {item.includes("_fav_") ? (
                                      <StarBorderIcon />
                                    ) : (
                                      <StarBorderIcon />
                                    )}
                                  </IconButton>
                                }
                                actionPosition="left"
                                className={classes.titleBar}
                              />
                            </ImageListItem>
                          )
                        )}
                      </ImageList>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <YT videoId={"FEtf7EMGb5U"} />
                    </Grid>
                  </Grid>
                </Grid>

                <Modal
                  open={this.state.showImage}
                  onClose={() => {}}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    className={classes.lightBox}
                  >
                    <Grid item xs={12}>
                      <Box className={classes.lightMedia}>
                        <Slide
                          direction={
                            this.state.mediaDirection ? "left" : "right"
                          }
                          in={this.state.mediaView}
                          onExited={() => {
                            this.setState({
                              mediaDirection: !this.state.mediaDirection,
                              mediaView: true,
                              imageIdx: this.state._imageIdx,
                            });
                          }}
                        >
                          <div
                            style={{
                              backgroundImage:
                                "url('" +
                                this.state.productBilder[this.state.imageIdx] +
                                "')",
                            }}
                            onTouchStart={(touchStartEvent) => {
                              this.touchStartPosX =
                                touchStartEvent.targetTouches[0].clientX;
                            }}
                            onTouchMove={(touchMoveEvent) => {
                              this.touchEndPosX =
                                touchMoveEvent.targetTouches[0].clientX;
                            }}
                            onTouchEnd={() => {
                              if (
                                this.touchStartPosX - this.touchEndPosX >
                                75
                              ) {
                                this.imgNext(false);
                              }
                              if (
                                this.touchStartPosX - this.touchEndPosX <
                                -75
                              ) {
                                this.imgPriv(true);
                              }
                            }}
                            className={classes.lightImg}
                          />
                        </Slide>
                      </Box>
                      <Box className={classes.favClose}>
                        <Fab
                          aria-label="Close"
                          className={classes.fab}
                          onClick={() => this.setState({ showImage: false })}
                        >
                          <CloseIcon />
                        </Fab>
                      </Box>
                      <Box className={classes.favs}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Fab
                              aria-label="left"
                              className={classes.fab}
                              onClick={() => this.imgPriv(true)}
                            >
                              <NavigateBeforeIcon />
                            </Fab>
                          </Grid>

                          <Grid item>
                            <Fab
                              aria-label="right"
                              className={classes.fab}
                              onClick={() => this.imgNext(false)}
                            >
                              <NavigateNextIcon />
                            </Fab>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Modal>

                <Grid item xs={12}>
                  <Social />
                </Grid>
              </Grid>
            </Frame>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withRouter(ProductBilder));
