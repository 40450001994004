import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: blue[500],
    },
    secondary: {
      main: green[500],
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#666",
        color: "#aaa",
      },
    },
    MuiStepIcon: {
      root: {
        "&$completed": {
          color: "green",
        },
        "&$active": {
          //color: "blue",
        },
      },
      active: {},
      completed: {},
    },
  },
});
