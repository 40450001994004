import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import BaseComponent from "./BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Menu from "./Menu";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0),
      width: "100%",
    },
    appbar: {
      borderRadius: 3,
    },
    headerImg: {
      width: "100%",
      objectFit: "contain",
    },
    header: {
      marginBottom: theme.spacing(0),
    },
    htag: {
      textAlign: "right",
      width: "100%",
      paddingRight: theme.spacing(2),
    },
    list: {
      width: 320,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "block",
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
  title: string;
}

interface IStates {
  menuInView: boolean;
}

class Frame extends BaseComponent<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      menuInView: false,
    };
  }

  toggleMenu(event: React.KeyboardEvent | React.MouseEvent) {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    this.setState({ menuInView: !this.state.menuInView });
  }
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes, children } = this.props;

    return (
      <React.Fragment>
        <Grid
          className={classes.root}
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <AppBar position="static" className={classes.appbar}>
              <Toolbar>
                <IconButton
                  onClick={(e) => this.toggleMenu(e)}
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                >
                  <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                  anchor="left"
                  open={this.state.menuInView}
                  onClose={() => this.setState({ menuInView: false })}
                  onOpen={() => this.setState({ menuInView: true })}
                >
                  <div
                    className={classes.list}
                    role="presentation"
                    onClick={() => this.setState({ menuInView: false })}
                    onKeyDown={() => this.setState({ menuInView: false })}
                  >
                    <Menu />
                  </div>
                </SwipeableDrawer>
                <Typography
                  className={classes.htag}
                  variant="h6"
                  color="inherit"
                >
                  <strong>{this.props.title}</strong>
                </Typography>
              </Toolbar>
            </AppBar>
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withRouter(Frame));
