import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BaseComponent from "./BaseComponent";
import { Card, CircularProgress, CardMedia, Fade, CardContent } from "@material-ui/core";

import { red } from "@material-ui/core/colors";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      height: "100hv",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9,
    },
    card: {
      position: "relative",
    },
    overlay: {
      position: "absolute",
      top: "0px",
      left: "0px",
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
  timeout: number;
  images: Array<string>;
}

interface IStates {
  loading: boolean;
  showImage: boolean;
  imageIdx: number;
}

class ImgContentBox extends BaseComponent<IProps, IStates> {
  interval: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      showImage: true,
      imageIdx: 1,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ showImage: !this.state.showImage });
    }, this.props.timeout);
  }

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    if (loading) {
      return (
        <React.Fragment>
          <CircularProgress />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <Fade
            timeout={1000}
            in={this.state.showImage}
            onExited={() => {
              let i = this.state.imageIdx;
              i++;
              if (i >= this.props.images.length) {
                i = 0;
              }
              this.setState({ imageIdx: i, showImage: true });
            }}
          >
            <CardMedia
              className={classes.media}
              image={this.props.images[this.state.imageIdx]}
              title="img"
              onClick={() => {
                clearInterval(this.interval);
                this.setState({ showImage: !this.state.showImage }, () => {
                  this.interval = setInterval(() => {
                    this.setState({ showImage: !this.state.showImage });
                  }, this.props.timeout);
                });
              }}
            />
          </Fade>
          <div className={classes.overlay}>{this.props.children}</div>
        </Card>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withRouter(ImgContentBox));
