import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BaseComponent from "./BaseComponent";
import { Card, CircularProgress, CardMedia, Fade, Grid, Typography } from "@material-ui/core";

import { red } from "@material-ui/core/colors";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      height: "100hv",
    },
    content: {
      paddingTop: theme.spacing(2),
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {}

interface IStates {}

class Datenschutz extends BaseComponent<IProps, IStates> {
  interval: any;

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12}>
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" component="p">
                  Datenschutzerklärung
                  <br />
                  <br />
                </Typography>

                <Typography variant="body2" color="textSecondary" component="p">
                  <br />
                  <br />
                  1. Grundlegendes <br />
                  Der Schutz Ihrer Privatsphäre ist uns ein wichtiges Anliegen. Deshalb möchten wir Sie bitten, die nachfolgende Zusammenfassung über die
                  Datenerhebung durch unseren Webdienst www.raynepgrocks.de aufmerksam zu lesen. Die hier aufgeführte Datenschutzerklärung soll über die Art, den
                  Umfang und den Zweck der Erhebung personenbezogener Daten durch den Websitebetreiber Restaurant-Servus aufklären. Die Datenerhebung und
                  -weitergabe entspricht den Anforderungen der DSGVO und des BDSG. Änderungen und entsprechende Aktualisierungen der Datenschutzerklärung sind nicht
                  auszuschließen, weshalb wir Ihnen empfehlen, diese Datenschutzerklärung regelmäßig zur Kenntnis zu nehmen. Wir versichern, dass diese Daten
                  ausschließlich dem Funktionieren, der statistischen Auswertung und der Verbesserung unseres Angebotes dienen. Laut geltenden Vorschriften werden
                  diese Daten nur für die Zeit gespeichert, wie es für die beschriebenen Zwecke notwendig ist. Ausnahmen gelten, wenn eine längere Speicherung durch
                  die DSGVO vorgesehen ist. Die gesetzlich geltenden Löschvorschriften werden eingehalten. Wir nehmen den Schutz Ihrer Daten ernst und behandeln
                  diese vertraulich. Sollten Sie dennoch Anliegen oder Fragen bzgl. der Erhebung Ihrer Daten haben, finden Sie am Ende des Textes die entsprechenden
                  Kontaktdaten zuständiger Ansprechpartner.
                </Typography>

                <Typography variant="body2" color="textSecondary" component="p">
                  <br />
                  <br />
                  2. Personenbezogene Daten
                  <br />
                  Diese Website erhebt und nutzt personenbezogene Daten. Die hier vorgenommene Datenerhebung entspricht einem berechtigten Interesse laut Art.6 Abs.
                  1 Buchstabe f der DSGVO. Zudem sind wir für die Bereitstellung unseres Services zu Teilen auf Informationen unserer Nutzer angewiesen. Daten
                  werden nur in dem Umfang erhoben, verwertet und an Dritte weitergeleitet, wie dies im gesetzlichen Rahmen erlaubt ist und/oder wenn Nutzer dem
                  zugestimmt haben. Wir als Betreiber dürfen personenbezogene Daten nur dann länger speichern, herausgeben oder nachträglich auf diese zugreifen,
                  soweit dies im rechtlich gestattet ist (z.B. bei Verdacht auf rechtswidrige Aktivitäten). Durch das Besuchen und Benutzen unserer Seite können
                  verschiedene personenbezogene Daten nach Art.4 DSGVO erhoben werden, welche Sie im Folgenden aufgelistet finden. Zugriffsdaten Im Zuge eines
                  automatischen Protokolls des verarbeitenden Computersystems wird eine Logdatei erstellt. Das bedeutet, dass Informationen Ihres verwendeten
                  Endgerätes als Logfiles auf einem Server festgehalten werden. Durch den Zugriff auf unsere Seite erheben wir solche Daten, welche als
                  Server-Logfiles abgelegt werden. Dies erfolgt aus Sicherheitsgründen. Server-Logfiles werden für eine Zeit von höchstens sieben Tagen gespeichert
                  und anschließend gelöscht. Die so erhobenen, personenbezogenen Daten umfassen: Zugriff auf die Seite: Datum, Uhrzeit, Häufigkeit wie Sie auf die
                  Seite gelangt sind (vorherige Seite, Hyperlink etc.) Menge der gesendeten Daten welchen Browser und welche Version desselben Sie verwenden Ihre
                  IP-Adresse Sie können unser Angebot auch nutzen, ohne Informationen Ihrer Person preiszugeben. Im Regelfall werden wir dennoch Ihre anonymisierten
                  Zugriffsdaten speichern, ohne Rückschluss auf die natürliche Person. Kontaktdaten über Formulare und Kommentarfunktion Wenn Sie mit uns Kontakt
                  aufnehmen (über das Kontaktformular, die Kommentarfunktion, E-Mail etc.) dann werden im Regelfall Ihr Name, Ihre E-Mail-Adresse und Ihre
                  IP-Adresse erhoben. Auch hierfür besteht ein berechtigtes Interesse unsererseits, wenn z. B. Ihr Kommentar gegen geltendes Recht verstößt. Zudem
                  behalten wir es uns grundsätzlich vor, Äußerungen mit menschenverachtenden, diffamierenden, homophoben und ähnlichen hetzerischen Inhalten zu
                  löschen und, wenn notwendig, zur Anzeige zu bringen.
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <br />
                  <br />
                  2.1. Besonders personenbezogene Daten
                  <br />
                  Es werden keine besonderen personenbezogenen Daten nach Art. 9 DSGVO erhoben.
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <br />
                  <br />
                  3. Datenweitergabe an Dritte
                  <br />
                  Grundsätzlich werden Ihre Daten von uns nicht an Dritte vermittelt. Außerdem stellen wir durch entsprechende Maßnahmen und regelmäßige Kontrollen
                  sicher, dass die von uns erhobenen Daten nicht durch Dritte von außen eingesehen oder abgegriffen werden können.
                </Typography>

                <Typography variant="body2" color="textSecondary" component="p">
                  <br />
                  <br />
                  4. Cookies
                  <br />
                  Diese Website setzt Cookies ein. Dies sind kleine Textdateien, welche in Ihrem Browser platziert werden. Auf die Art werden Ihr Browser und Ihr
                  Gerät bei einer nächsten Anmeldung wiedererkannt. Dadurch können bei Ihrem nächsten Besuch bereits getätigte Einstellungen und andere Änderungen,
                  welche Sie vorgenommen haben, rekonstruiert werden. Wir verwenden Cookies, um unseren Dienst zu personalisieren und somit benutzerfreundlicher zu
                  gestalten Betrugsversuche zu verhindern und Sicherheit zu gewährleisten interessenbasierte Werbung zu schalten pseudonymisierte Reichweitenmessung
                  durchzuführen Sie haben jederzeit die Möglichkeit, das Setzen von Cookies zu blockieren und bereits gesetzte Cookies in Ihrem verwendeten Browser
                  zu löschen. In solch einem Fall müssen wir Sie darauf hinweisen, dass bestimmte Features auf der Seite eventuell nicht mehr oder nur noch
                  eingeschränkt funktionieren. Wünschen Sie nicht, dass Cookies zur Reichweitenmessung eines bestimmten Anbieters eingesetzt werden, dann können Sie
                  über folgende Seiten entsprechende Einstellungen tätigen: Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative:
                  http://optout.networkadvertising.org/?c=1#!/ Cookie-Deaktivierungsseite der US-amerikanischen Website: http://optout.aboutads.info/?c=2#!/
                  Cookie-Deaktivierungsseite der europäischen Website: http://optout.networkadvertising.org/?c=1#!/
                </Typography>

                <Typography variant="body2" color="textSecondary" component="p">
                  <br />
                  <br />
                  5. Newsletter und Abonnement
                  <br />
                  Wir versenden keine Newsletter.
                </Typography>

                <Typography variant="body2" color="textSecondary" component="p">
                  <br />
                  <br />
                  6. Webanalysediente
                  <br />
                  Diese Seite verwendet keine Analysedienste.
                </Typography>

                <Typography variant="body2" color="textSecondary" component="p">
                  <br />
                  <br />
                  6. Webanalysediente
                  <br />
                  Diese Seite verwendet keine Analysedienste.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(withRouter(Datenschutz));
