import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BaseComponent from "./BaseComponent";
import { Card, CircularProgress, CardMedia, Fade } from "@material-ui/core";

import { red } from "@material-ui/core/colors";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      height: "100hv",
    },
    content: {
      paddingTop: theme.spacing(2),
    },

    logo: {
      height: 0,
      paddingTop: "22.6%", // 16:9,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9,
    },
    avatar: {
      backgroundColor: red[500],
    },
    sozialButtons: {
      justifyContent: "center",
    },
    sozialIcons: {
      "& svg": {
        fontSize: 50,
      },
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
    videoId : string,
}

interface IStates {}

class YT extends BaseComponent<IProps, IStates> {
  interval: any;

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;

    return <React.Fragment>
        <div
            className="video"
            style={{
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 */,
                paddingTop: 25,
                height: 0
            }}
        >
            <iframe
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }}
                src={"https://www.youtube.com/embed/"+this.props.videoId}
                frameBorder="0"
            />
        </div>
    </React.Fragment>;
  }
}
export default withStyles(styles)(withRouter(YT));
