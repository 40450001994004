import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import RPG from "./lib/RPG";
import { ThemeProvider } from "@material-ui/core/styles";
import { darkTheme } from "./style/theme";
import { CssBaseline } from "@material-ui/core";
import MUICookieConsent from "material-ui-cookie-consent";

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />

        <Router>
          <RPG />
        </Router>
      </ThemeProvider>
    </React.Fragment>
  );
}
export default App;
