import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import BaseComponent from "./BaseComponent";
import {
  Grid,
  IconButton,
  CardContent,
  Card,
  CircularProgress,
  CardHeader,
  Avatar,
  CardMedia,
  CardActions,
  Fade,
  Button,
} from "@material-ui/core";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import Frame from "./Frame";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";

import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import ImgContentBox from "./ImgContentBox";
import Impress from "./Impress";
import Datenschutz from "./Datenschutz";
import Social from "./Social";
import ProductBilder from "./ProductBilder";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    mainContent: {
      padding: theme.spacing(2),
    },
    logo: {
      maxHeight: "60%",
      maxWidth: "70%",
      marginRight: "auto",
      marginLeft: "auto",
    },

    teaserText: {
      textAlign: "center",
      fontSize: "20px",
      marginRight: "auto",
      marginLeft: "auto",
    },
    media: {
      //height: 0,
      //paddingTop: "56.25%", // 16:9,
    },
    avatar: {
      backgroundColor: red[500],
    },
    sozialButtons: {
      justifyContent: "center",
    },
    sozialIcons: {
      "& svg": {
        fontSize: 50,
      },
    },
    mainSelect: {
      width: "100%",
      height: "10vh",
      opacity: 0.7,
    },

    imageList: {
      width: "100%",
      transform: "translateZ(0)",
    },

    icon: {
      color: "white",
    },

    titleBar: {
      background:
        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {}

interface IStates {
  loading: boolean;
}

class RPG extends BaseComponent<IProps, IStates> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (window.location.pathname.toLowerCase() === "/angebot") {
      this.sendMessage("angebot call");
    }
  }

  componentWillUnmount() {}

  clientInfo(): string {
    return (
      "TZ:" +
      new Date().getTimezoneOffset() / 60 +
      " [" +
      navigator.language +
      "] : " +
      window.location.href +
      " -> (" +
      navigator.userAgent +
      ")"
    );
  }

  sendMessage(message: string): string {
    let url: URL = new URL(
      "https://www.professionelle-produktbilder.de/telegram.php"
    );
    let params: any = { m: message + "\n" + this.clientInfo(), s: "true" };
    url.search = new URLSearchParams(params).toString();

    fetch(url.toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
      .then((res) => res.json())
      .then((res) => {})
      .catch((res) => {});

    return "";
  }
  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    if (loading) {
      return (
        <React.Fragment>
          <CircularProgress />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/impressum">
            <Helmet>
              <title>{process.env.REACT_APP_TITLE} | Impressum</title>
              <link rel="canonical" href={window.location.href} />
              <meta
                name="description"
                content={process.env.REACT_APP_DESCRIPTION}
              />
            </Helmet>

            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              className={classes.mainContent}
            >
              <Grid item>
                <Grid container spacing={2} className={classes.root}>
                  <Grid item xs={12}>
                    <Frame title={"Impressum"}>
                      <Impress />
                    </Frame>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Route>

          <Route exact path="/datenschutzerklaerung">
            <Helmet>
              <title>
                {process.env.REACT_APP_TITLE} | Datenschutzerklärung
              </title>
              <meta
                name="description"
                content={process.env.REACT_APP_DESCRIPTION}
              />
              <link rel="canonical" href={window.location.href} />
            </Helmet>

            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              className={classes.mainContent}
            >
              <Grid item>
                <Grid container spacing={2} className={classes.root}>
                  <Grid item xs={12}>
                    <Frame title={"Datenschutz"}>
                      <Datenschutz />
                    </Frame>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Route>

          <Route exact path="*">
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              className={classes.mainContent}
            >
              <Grid item>
                <ProductBilder />
              </Grid>
            </Grid>
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(withRouter(RPG));
