import React, { ChangeEvent } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BaseComponent from "./BaseComponent";
import {
  Collapse,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    blockSpace: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
    },
    fieldsInput: {
      minWidth: 120,
      width: "100%",
    },
    formControl: {
      minWidth: 120,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    caption: {
      opacity: 0.5,
      paddingButtom: theme.spacing(2),
    },
  });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {}

interface IStates {
  typePrice: number;
  countArtikel: number;
  countViews: number;
  output: number;
  showConnect: boolean;
  timePrice: number;
  firma: string;
  name: string;
  email: string;
  telefon: string;
  kontakt: string;
  comment: string;
  dsgvo: boolean;
}

class ProductCalc extends BaseComponent<IProps, IStates> {
  interval: any;

  outputSetting = [
    { k: 0.0, v: "JPG Hintergrund Weiß" },
    { k: 1.0, v: "PNG Hintergrund Transparent" },
    { k: 1.5, v: "JPG & PNG" },
  ];

  timePriceSetting = [
    { k: 1.5, v: "lieber gestern als Heute" },
    { k: 1.2, v: "innerhalb 1 Woche" },
    { k: 1.0, v: "innerhalb 2 Wochen" },
  ];

  typePriceSettings = [
    { k: 10, v: "Größe S - ca. 10cm (zb. Zigarettenschachtel, Handy)" },
    { k: 12, v: "Größe M - ca. 25cm (zb. Schuhe, Laptops)" },
    { k: 20, v: "Größe L - ca. 50cm" },
    { k: 25, v: "Größe XL - ca. 100cm" },
    { k: -1.0, v: "nur vor Ort fotografierbar" },
    { k: -2.0, v: "Imagebilder (Web/Print)" },
  ];

  constructor(props: IProps) {
    super(props);
    this.state = {
      typePrice: 12,
      countArtikel: 0,
      countViews: 1,
      output: 0,
      showConnect: false,
      firma: "",
      name: "",
      email: "",
      telefon: "",
      kontakt: "email",
      comment: "",
      timePrice: 1,
      dsgvo: false,
    };
  }

  rabatt(val: number, p: number): number {
    return val - (val * p) / 100;
  }

  sendMessage(message: string, afterSend = () => {}) {
    let url: URL = new URL(
      "https://www.professionelle-produktbilder.de/telegram.php"
    );
    let params: any = { m: message, s: "false" };
    url.search = new URLSearchParams(params).toString();

    fetch(url.toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        afterSend();
      })
      .catch((res) => {});
  }

  getValueFromKey(ar: Array<any>, key: any) {
    for (let i = 0; i < ar.length; i++) {
      if (ar[i].k === key) {
        return ar[i].v;
      }
    }
    return "unknown";
  }

  send2Telegram() {
    this.sendMessage(
      "Größe: " +
        this.getValueFromKey(this.typePriceSettings, this.state.typePrice) +
        " " +
        this.state.typePrice +
        "€ | " +
        this.state.countArtikel +
        " Artikel , " +
        this.state.countViews +
        " Ansichten , Zeit : " +
        this.getValueFromKey(this.timePriceSetting, this.state.timePrice) +
        " , Ausgable als " +
        this.getValueFromKey(this.outputSetting, this.state.output) +
        "\n---------\n" +
        "Bilder: " +
        this.state.countArtikel * this.state.countViews +
        " , Summe: " +
        this.formatPrice(this.calcPrice()) +
        "\n---------\n" +
        this.state.firma +
        " , " +
        this.state.name +
        " , " +
        this.state.email +
        " , " +
        this.state.telefon +
        " , " +
        this.state.kontakt +
        "\n---------\n" +
        this.state.comment,
      () => {
        this.setState({ showConnect: false });
      }
    );
  }

  checkForm() {
    if (!this.state.dsgvo) {
      return true;
    }
    return !(
      this.state.firma !== "" &&
      !this.fcheck(this.state.firma, 3) &&
      this.state.name !== "" &&
      !this.fcheck(this.state.name, 3) &&
      this.state.email !== "" &&
      !this.fcheck(this.state.email, 5) &&
      this.state.telefon !== "" &&
      !this.fcheck(this.state.telefon, 6)
    );
  }

  calcPrice() {
    let tp = this.state.typePrice;
    if (tp < 0) {
      return -1;
    }
    let cv = this.state.countViews;

    if (this.state.countArtikel > 4) {
      tp = this.rabatt(this.state.typePrice, 15);
    }
    if (this.state.countArtikel > 30) {
      tp = this.rabatt(this.state.typePrice, 20);
    }
    if (this.state.countArtikel > 50) {
      tp = this.rabatt(this.state.typePrice, 25);
    }

    let base = tp * this.state.countArtikel;
    let view = 0;
    if (cv > 1) {
      view = (tp / 3) * ((cv - 1) * this.state.countArtikel);
    }
    let out = this.state.countArtikel * cv * this.state.output;
    return (base + view + out) * this.state.timePrice;
  }

  formatPrice(p: number) {
    let formatter = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    });
    return formatter.format(p);
  }

  fcheck(s: string, len: number): boolean {
    if (s === undefined || s === "") {
      return false;
    }
    if (s.length < len) {
      return true;
    }
    return false;
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;
    const price = this.calcPrice();
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Grid item xs={12}>
              <Grid container className={classes.root} spacing={4}>
                <Grid item xs={12} sm={6} md={3} xl={3}>
                  <Typography
                    variant="h6"
                    component="h1"
                    align="left"
                    className={classes.blockSpace}
                  >
                    Größe der Artikel
                  </Typography>

                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={"" + this.state.typePrice}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.setState({ typePrice: parseInt(e.target.value) });
                    }}
                  >
                    {this.typePriceSettings.map((val) => {
                      return (
                        <FormControlLabel
                          value={"" + val.k}
                          control={<Radio color="primary" />}
                          label={"" + val.v}
                        />
                      );
                    })}
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        component="h1"
                        align="left"
                        className={classes.blockSpace}
                      >
                        Schückzahlen
                      </Typography>

                      <TextField
                        className={classes.fieldsInput}
                        label="Anzahl der Artikel"
                        type="number"
                        color="primary"
                        defaultValue={this.state.countArtikel}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          let v = parseInt(e.target.value);
                          if (isNaN(v)) {
                            v = 0;
                          }
                          if (v < 0) {
                            v = 0;
                          }
                          this.setState({ countArtikel: v });
                        }}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.fieldsInput}
                        label="Ansichten pro Artikel"
                        type="number"
                        color="primary"
                        defaultValue={this.state.countViews}
                        variant="filled"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          let v = parseInt(e.target.value);
                          if (isNaN(v)) {
                            v = 1;
                          }
                          if (v < 1) {
                            v = 1;
                          }
                          this.setState({ countViews: v });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Typography
                        variant="caption"
                        component="h6"
                        align="center"
                        className={classes.caption}
                      >
                        Für Amazon sind 7 Ansichten zu empfehlen.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        component="h1"
                        align="left"
                        className={classes.blockSpace}
                      >
                        Gewünschte Lieferzeit
                      </Typography>
                      <RadioGroup
                        aria-label="timePrice"
                        name="timePrice"
                        value={"" + this.state.timePrice}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          this.setState({
                            timePrice: parseFloat(e.target.value),
                          });
                        }}
                      >
                        {this.timePriceSetting.map((val) => {
                          return (
                            <FormControlLabel
                              value={"" + val.k}
                              control={<Radio color="primary" />}
                              label={"" + val.v}
                            />
                          );
                        })}
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={3}>
                  <Typography
                    variant="h6"
                    component="h1"
                    align="left"
                    className={classes.blockSpace}
                  >
                    Ausgabeformat
                  </Typography>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={"" + this.state.output}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.setState({ output: parseFloat(e.target.value) });
                    }}
                  >
                    {this.outputSetting.map((val) => {
                      return (
                        <FormControlLabel
                          value={"" + val.k}
                          control={<Radio color="primary" />}
                          label={"" + val.v}
                        />
                      );
                    })}
                  </RadioGroup>
                </Grid>

                <Grid item xs={12} sm={6} md={3} xl={3}>
                  <Typography
                    variant="h6"
                    component="h1"
                    align="left"
                    className={classes.blockSpace}
                  >
                    Vorkalkulierter Preis
                  </Typography>
                  <Typography
                    variant="h3"
                    component="h1"
                    className={classes.blockSpace}
                  >
                    {this.state.countArtikel * this.state.countViews} Bilder
                  </Typography>

                  {price >= 0 ? (
                    <React.Fragment>
                      <Typography
                        variant="h3"
                        component="h1"
                        className={classes.blockSpace}
                      >
                        {this.formatPrice(price)}
                      </Typography>
                      {price > 0 ? (
                        <Typography
                          component="p"
                          className={classes.blockSpace}
                        >
                          Umsatzsteuerfreie Leistungen
                          <br />
                          gemäß §19 UStG.
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ) : (
                    <Typography
                      variant="h6"
                      component="h1"
                      align="center"
                      className={classes.blockSpace}
                    >
                      {this.state.showConnect ? (
                        <React.Fragment>
                          Bitte Beschreiben sie Ihre Artikel im Kommentarfeld.
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          Bitte klicken Sie auf{" "}
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                              this.setState({
                                showConnect: !this.state.showConnect,
                              });
                            }}
                          >
                            Weiter
                          </Button>{" "}
                          und beschreiben sie Ihre Artikel im Kommentarfeld.
                        </React.Fragment>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Collapse in={this.state.countArtikel > 0}>
                <Grid item xs={12}>
                  <Grid container className={classes.root} justify="center">
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={
                          this.state.showConnect ? (
                            <ExpandLessIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )
                        }
                        onClick={() => {
                          this.setState(
                            {
                              showConnect: !this.state.showConnect,
                            },
                            () => {
                              if (this.state.showConnect) {
                                this.sendMessage(
                                  "Submenu aufgeklappt" +
                                    "\n---------\n" +
                                    this.getValueFromKey(
                                      this.typePriceSettings,
                                      this.state.typePrice
                                    ) +
                                    " " +
                                    this.state.typePrice +
                                    "€ | " +
                                    this.state.countArtikel +
                                    " Artikel , " +
                                    this.state.countViews +
                                    " Ansichten , Zeit : " +
                                    this.getValueFromKey(
                                      this.timePriceSetting,
                                      this.state.timePrice
                                    ) +
                                    " , Ausgable als " +
                                    this.getValueFromKey(
                                      this.outputSetting,
                                      this.state.output
                                    ) +
                                    "\n---------\n" +
                                    "Bilder: " +
                                    this.state.countArtikel *
                                      this.state.countViews +
                                    " , Summe: " +
                                    this.formatPrice(this.calcPrice())
                                );
                              }
                            }
                          );
                        }}
                        size="large"
                      >
                        Weiter
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={this.state.showConnect}>
                <Grid item xs={12}>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} sm={6} xl={6}>
                      <TextField
                        className={classes.fieldsInput}
                        error={this.fcheck(this.state.firma, 3)}
                        label="Firma"
                        required
                        defaultValue={this.state.firma}
                        onChange={(e) => {
                          this.setState({ firma: e.target.value });
                        }}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                      <TextField
                        className={classes.fieldsInput}
                        error={this.fcheck(this.state.name, 3)}
                        label="Name"
                        required
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                        defaultValue={this.state.name}
                        variant="filled"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} xl={6}>
                      <TextField
                        className={classes.fieldsInput}
                        error={this.fcheck(this.state.email, 5)}
                        label="eMail"
                        required
                        defaultValue={this.state.email}
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                      <TextField
                        error={this.fcheck(this.state.telefon, 6)}
                        className={classes.fieldsInput}
                        label="Telefon"
                        defaultValue={this.state.telefon}
                        required
                        onChange={(e) => {
                          this.setState({ telefon: e.target.value });
                        }}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="filled-age-native-simple">
                          Kontakt per:
                        </InputLabel>
                        <Select
                          native
                          value={this.state.kontakt}
                          onChange={(e: any) => {
                            this.setState({ kontakt: e.target.value });
                          }}
                          inputProps={{
                            name: "KontaktVia",
                            id: "filled-age-native-simple",
                          }}
                        >
                          <option value={"email"}>eMail</option>
                          <option value={"Telefon"}>Telefon</option>
                          <option value={"WhatsApp"}>WhatsApp</option>
                          <option value={"Telegram"}>Telegram</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                      <TextField
                        className={classes.fieldsInput}
                        label="Kommentare / Produkte / Links"
                        placeholder=""
                        onChange={(e) => {
                          this.setState({ comment: e.target.value });
                        }}
                        defaultValue={this.state.comment}
                        multiline
                        variant="filled"
                        rows={4}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.dsgvo}
                            onChange={() => {
                              this.setState({ dsgvo: !this.state.dsgvo });
                            }}
                            name="dsgvo"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        }
                        label="Ich habe die Informationen zum Datenschutz zur Kenntnis genommen."
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        disabled={this.checkForm()}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<MailOutlineIcon />}
                        size="large"
                        onClick={() => {
                          this.send2Telegram();
                        }}
                      >
                        Unverbindliche Anfrage Absenden
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(ProductCalc));
